import { renderOptionInputPickerTheme } from "@app/core/condition/[id]/components/general/form-element/config";
import {
  ApplicationMetadata_NewRegistration,
  ApplicationType,
  Condition,
  IParentConditionSection,
  Svc_Condition_Group,
  Svc_Condition_Lovs,
} from "@app/core/condition/[id]/model";
import {
  changeConditionGeneralForm,
  getConditionGeneralForm,
} from "@app/core/condition/[id]/util";
import {
  BubbleUpType,
  Svc_BubbleUpIdentifier,
} from "@app/core/inspections/[id]/model";
import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { DBRowState } from "@app/products/crms/[id]/model";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import {
  ConditionCategory,
  ConditionType,
} from "@common/constants/enumerations";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { Keyword } from "@common/models/keyword";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { mapEnum, nameOfFactory } from "@common/utils/common";
import {
  requiredValidator,
  validatorDateValueWithMinMax,
} from "@common/utils/field-validators";
import { CCComboxSearchLov } from "@components/cc-combox-search-lov/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FormElement } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React, { useCallback, useMemo, useState } from "react";

const nameOf = nameOfFactory<Condition>();
const nameOfGroup = nameOfFactory<Svc_Condition_Group>();

export const ConditionFormElement = () => {
  //#region STORE ========/
  const { dataForms } = useFlexibleFormStore();
  const { settings } = useCommonCoreStore();
  const isNew = useIsNew();
  //#endregion STORE =====/

  const condition = getConditionGeneralForm() as Condition;
  const conditionLovs = dataForms?.GeneralFormLovs as Svc_Condition_Lovs;
  const [groups, setGroups] = useState(conditionLovs?.Groups ?? []);

  const parentState = getConditionGeneralForm("CCFormOptions")
    ?.parentState as IParentConditionSection;

  const recordType = useMemo(() => {
    if (isNew) {
      return parentState?.recordType;
    } else {
      const bubbleUps = condition?._BubbleUps;
      if (bubbleUps) {
        const parent = bubbleUps.find(
          (bubbleUp: Svc_BubbleUpIdentifier) =>
            bubbleUp.BubbleUpType_ENUM === BubbleUpType.Parent
        );
        return parent?.SourceIdentifier?._RecordSourceType_ENUM;
      }
    }
  }, [condition?._BubbleUps, isNew, parentState?.recordType]);

  const isAdhocEnableAdvancedMode = getBoolValueSetting(
    settings[
      ECorporateSettingsField
        .CorporateSettings_Conditions_Adhoc_EnableAdvancedMode
    ]
  );

  const isConditionInGroupHealthManager = getBoolValueSetting(
    settings[
      ECorporateSettingsField
        .HealthManager_Views_Conditions_DisplayConditionsInGroup
    ]
  );

  const isConditionInGroupWasteWater = getBoolValueSetting(
    settings[
      ECorporateSettingsField.WW_Views_Conditions_DisplayConditionsInGroup
    ]
  );

  const isConditionInGroupLLPermit = getBoolValueSetting(
    settings[
      ECorporateSettingsField.LLP_Views_Conditions_DisplayConditionsInGroup
    ]
  );

  const isEnableCategory = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_Keyword_Categories]
  );

  const isDateMandatory = useMemo(() => {
    if (
      condition?.ConditionCategory_ENUM !== ConditionCategory.SRU_SRS &&
      (recordType === RECORDTYPE.SRU_Application ||
        recordType === RECORDTYPE.SRU_Organisation)
    ) {
      return true;
    } else {
      return false;
    }
  }, [condition?.ConditionCategory_ENUM, recordType]);

  const isShowGroup = useMemo(() => {
    if (
      (recordType === RECORDTYPE.HealthManager_Premises &&
        isConditionInGroupHealthManager) ||
      (recordType === RECORDTYPE.WW_System && isConditionInGroupWasteWater) ||
      (recordType === RECORDTYPE.LLP_Permit && isConditionInGroupLLPermit)
    ) {
      return true;
    }
    return false;
  }, [
    isConditionInGroupHealthManager,
    isConditionInGroupLLPermit,
    isConditionInGroupWasteWater,
    recordType,
  ]);

  const isShowPublicRegisterDescription = useMemo(() => {
    if (
      condition?.AllowPublish &&
      isAdhocEnableAdvancedMode &&
      isNil(condition?.ConditionReference_ID)
    ) {
      return true;
    }
    return false;
  }, [
    condition?.AllowPublish,
    condition?.ConditionReference_ID,
    isAdhocEnableAdvancedMode,
  ]);

  const isShowNotesAndAdhocCondition = useMemo(() => {
    if (isAdhocEnableAdvancedMode && isNil(condition?.ConditionReference_ID)) {
      return true;
    }
    return false;
  }, [condition?.ConditionReference_ID, isAdhocEnableAdvancedMode]);

  const isDisableCategory = useMemo(() => {
    let lockCategory = false;
    const registration = parentState?.registration;
    const application = parentState?.application;
    if (isNew) {
      if (registration) {
        if (
          registration.DisabilityCategories_CSV &&
          registration.CYFCategories_CSV
        ) {
          changeConditionGeneralForm({
            ConditionCategory_ENUM: ConditionCategory.SRU_CYFAndDisability,
          });
        } else if (registration.CYFCategories_CSV) {
          changeConditionGeneralForm({
            ConditionCategory_ENUM: ConditionCategory.SRU_CYF,
          });
          lockCategory = true;
        } else if (registration.DisabilityCategories_CSV) {
          changeConditionGeneralForm({
            ConditionCategory_ENUM: ConditionCategory.SRU_Disability,
          });
          lockCategory = true;
        } else if (registration.SRSCategories_CSV) {
          changeConditionGeneralForm({
            ConditionCategory_ENUM: ConditionCategory.SRU_SRS,
          });
          lockCategory = true;
        }
      } else if (application) {
        if (
          application.Type_ENUM === ApplicationType.NewRegistration_CYFDIS ||
          application.Type_ENUM === ApplicationType.RegistrationRenewal_CYFDIS
        ) {
          const applicationMetadata =
            application.Metadata as ApplicationMetadata_NewRegistration;
          if (
            applicationMetadata.HasCYFService &&
            applicationMetadata.HasDisabilityService
          ) {
            changeConditionGeneralForm({
              ConditionCategory_ENUM: ConditionCategory.SRU_CYFAndDisability,
            });
          } else if (applicationMetadata.HasDisabilityService) {
            changeConditionGeneralForm({
              ConditionCategory_ENUM: ConditionCategory.SRU_Disability,
            });
            lockCategory = true;
          } else if (applicationMetadata.HasCYFService) {
            changeConditionGeneralForm({
              ConditionCategory_ENUM: ConditionCategory.SRU_CYF,
            });
            lockCategory = true;
          }
        } else if (
          application.Type_ENUM === ApplicationType.NewRegistration_SRS
        ) {
          changeConditionGeneralForm({
            ConditionCategory_ENUM: ConditionCategory.SRU_SRS,
          });
        }
      }
    } else {
      lockCategory = !!parentState?.isLockCategory;
    }
    return lockCategory;
  }, [
    isNew,
    parentState?.application,
    parentState?.isLockCategory,
    parentState?.registration,
  ]);

  const isInactive = useMemo(() => {
    return !!(
      mapEnum(condition?.Sys_DBRowState, DBRowState) === DBRowState.Inactive &&
      condition?.Condition_ID
    );
  }, [condition?.Sys_DBRowState, condition?.Condition_ID]);

  const handleValidateDateCommenced = useCallback(
    (value: Date) => {
      return isDateMandatory
        ? requiredValidator(value)
        : "" ||
            validatorDateValueWithMinMax(value, null, condition?.DateClosed);
    },
    [condition?.DateClosed, isDateMandatory]
  );

  const handleValidateDateClosed = useCallback(
    (value: Date) => {
      return validatorDateValueWithMinMax(
        value,
        condition?.DateCommenced,
        null
      );
    },
    [condition?.DateCommenced]
  );

  const handleChangeGroup = (data: any) => {
    changeConditionGeneralForm({ Group: data ? data?.Group : null });
  };

  const handleAddNewGroup = (searchKey: string) => {
    const groupIsExisted = groups?.some(
      (item: Svc_Condition_Group) => item.Group === searchKey
    );

    if (!groupIsExisted) {
      setGroups([
        ...groups,
        {
          Group: searchKey,
          ProductType_ENUM: null,
        } as Svc_Condition_Group,
      ]);

      changeConditionGeneralForm({ Group: searchKey });
    } else {
      changeConditionGeneralForm({ Group: searchKey });
    }
  };

  const handleSelectTheme = (theme: Keyword) => {
    if (theme) {
      changeConditionGeneralForm({
        Theme_KWD: theme.Keyword_ID,
        ThemeName: theme.Name,
      });
    } else {
      changeConditionGeneralForm({
        Theme_KWD: undefined,
        ThemeName: undefined,
      });
    }
  };

  return (
    <FormElement>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {!isAdhocEnableAdvancedMode && (
            <div className="cc-field">
              <CCLabel title="Sort order" isMandatory />
              <Field
                name={nameOf("SortIndex")}
                component={CCNumericTextBox}
                placeholder="Sort order"
                validator={requiredValidator}
                disabled={isInactive}
              />
            </div>
          )}

          {isShowGroup && (
            <div className="cc-field">
              <CCLabel title="Group" />
              <Field
                component={CCComboxSearchLov}
                name={nameOf("Group")}
                dataLov={groups ?? []}
                textField={nameOfGroup("Group")}
                dataItemKey={nameOfGroup("Group")}
                onChange={handleChangeGroup}
                valueField={condition?.Group}
                isShowNewFooter
                onChangeNewButton={handleAddNewGroup}
                disabled={isInactive}
              />
            </div>
          )}

          <div className="cc-field">
            <CCLabel title="Title" isMandatory />
            <Field
              name={nameOf("Title")}
              component={CCInput}
              placeholder="Title"
              validator={requiredValidator}
              disabled={isInactive}
            />
          </div>
        </div>
      </section>
      <hr className="cc-divider" />
      {isShowNotesAndAdhocCondition && (
        <>
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <CCValueField
                label="Type"
                value={
                  condition?.ConditionType_ENUM === ConditionType.Condition ||
                  parentState?.isCondition
                    ? "Condition"
                    : "Restriction"
                }
              />
              <div className="cc-field">
                <CCLabel title="Allow publish" isMandatory />
                <Field
                  name={nameOf("AllowPublish")}
                  component={CCSwitch}
                  checked={condition?.AllowPublish}
                  validator={requiredValidator}
                  disabled={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Category" isMandatory />
                <Field
                  name={nameOf("ConditionCategory_ENUM")}
                  component={CCDropDownList}
                  data={conditionLovs?.Category ?? []}
                  validator={requiredValidator}
                  isKeyValueDropdown
                  disabled={isDisableCategory || isInactive}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Theme" isMandatory />
                <Field
                  name={nameOf("ThemeName")}
                  placeholder="Select theme"
                  component={InputPickerSearch}
                  onChange={handleSelectTheme}
                  validator={requiredValidator}
                  options={renderOptionInputPickerTheme(
                    true,
                    PRODUCT_TYPE_NUMBER.LLPermits,
                    isEnableCategory
                  )}
                  disabled={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Date created" isMandatory />
                <Field
                  name={nameOf("DateCreated")}
                  component={CCDatePicker}
                  validator={requiredValidator}
                  format={DATE_FORMAT.DATE_CONTROL}
                  disabled={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Date commenced" isMandatory={isDateMandatory} />
                <Field
                  name={nameOf("DateCommenced")}
                  component={CCDatePicker}
                  validator={handleValidateDateCommenced}
                  format={DATE_FORMAT.DATE_CONTROL}
                  disabled={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Date due" isMandatory={isDateMandatory} />
                <Field
                  name={nameOf("DateDue")}
                  component={CCDatePicker}
                  validator={isDateMandatory ? requiredValidator : undefined}
                  format={DATE_FORMAT.DATE_CONTROL}
                  disabled={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Date closed" />
                <Field
                  name={nameOf("DateClosed")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  validator={handleValidateDateClosed}
                  disabled={isInactive}
                />
              </div>
            </div>
          </section>
          <hr className="cc-divider" />
        </>
      )}
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Condition" isMandatory />
            <Field
              name={nameOf("ConditionText")}
              placeholder="Condition"
              rows={6}
              component={CCTextArea}
              validator={requiredValidator}
              disabled={isInactive}
            />
          </div>
          {isShowPublicRegisterDescription && (
            <div className="cc-field">
              <CCLabel title="Public register description" isMandatory />
              <Field
                name={nameOf("PublicRegisterDescription")}
                placeholder="Public register description"
                rows={4}
                component={CCTextArea}
                validator={requiredValidator}
                disabled={isInactive}
              />
            </div>
          )}
          {isShowNotesAndAdhocCondition && (
            <div className="cc-field">
              <CCLabel title="Notes" />
              <Field
                name={nameOf("Notes")}
                placeholder="Notes"
                rows={4}
                component={CCTextArea}
                disabled={isInactive}
              />
            </div>
          )}
        </div>
      </section>
    </FormElement>
  );
};
