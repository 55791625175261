import { ChatSidebar } from "@app/core/chat/list/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Journal } from "@app/core/journal/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { CRMSEventContactTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/contact/_index";
import { CRMSEventDetailsTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/details/_index";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { NewEventWithSameDetailsButton } from "@app/products/crms/components/action-bar/buttons/new-event-with-same-details/_index";
import { NewEventWithSameIssueButton } from "@app/products/crms/components/action-bar/buttons/new-event-with-same-issue/_index";
import { NewEventButton } from "@app/products/crms/components/action-bar/buttons/new-event/_index";
import { CrmsActionBarNavDropdown } from "@app/products/crms/components/action-bar/nav-dropdown/_index";
import { colCollectionLookup } from "@app/products/crms/hard-rubbish/collection-lookup/config";
import { CRMSHardRubbishCollectionLookupBookmark } from "@app/products/crms/hard-rubbish/collection-lookup/util";
import { hardRubbishRoute } from "@app/products/crms/hard-rubbish/route";
import { crmsRoute } from "@app/products/crms/route";
import { loadAllCrms } from "@app/products/crms/store";
import { CRMSBookmark } from "@app/products/crms/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: crmsRoute.name,
    leftComponents: [
      <CrmsActionBarNavDropdown category={hardRubbishRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton title="New" type="sub">
        <NewEventButton />
        <NewEventWithSameDetailsButton />
        <NewEventWithSameIssueButton />
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_HardRubbishCollection}
        dataSetFilter={MAILMERGEDATASET.CUSTOMERSERVICE_Event}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.TownPlanning}
        recordType={RECORDTYPE.CUSTOMERSERVICE_HardRubbishCollection}
        keyField={"ID"}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CRMS_ROUTE}
        productType={PRODUCT_TYPE.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        detail={CRMSBookmark.getBookmarkDetail}
        displayName={CRMSBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          CRMSHardRubbishCollectionLookupBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={
          CRMSHardRubbishCollectionLookupBookmark.getBookmarkListViewDetail
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CRMSEventDetailsTab /> },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <CRMSEventContactTab />,
      },
      {
        title: "History",
        component: (
          <Journal
            id={13347}
            recordType={RECORDTYPE.CUSTOMERSERVICE_HardRubbishCollection}
          />
        ),
      },
      {
        title: "Ask AI",
        component: <ChatSidebar productId={10} />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colCollectionLookup}
      primaryField={"ID"}
      data={loadAllCrms() || []}
    />
  );
};
