import { isEmpty } from "lodash";

const bot: any = {
  id: 0,
};
const senderId: any = { id: 1 };

export const processMessages = (messagesData: any[]) => {
  const newMessages = messagesData.map((messageData) => {
    // Extract data from each message
    const { Sender, Created, Response } = messageData;

    // Define the output structure
    return {
      author: Sender === "AI" ? bot : senderId, // Assuming "Prompt" means the bot
      // suggestedActions: [
      //   {
      //     type: "reply",
      //     value: "Neat!", // You can customize this based on your logic
      //   },
      // ],
      timestamp: new Date(Created), // Parse the created date into a Date object
      text: Response, // Use response if available
    };
  });
  return newMessages.filter((item) => !isEmpty(item.text));
};
