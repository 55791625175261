import { getUUID } from "@common/utils/common";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
export interface IConversationId {
  HM?: string;
  CX?: string;
  Common?: string;
}
class AskAIService {
  private _isShowAskAI: boolean = false;
  private _conversationId: IConversationId = {
    HM: getUUID(),
    CX: getUUID(),
    Common: getUUID(),
  };

  constructor() {
    makeAutoObservable(this);
  }

  get isShowAskAI() {
    return this._isShowAskAI;
  }
  setIsShowAskAI = (isShowAskAI: boolean) => {
    runInAction(() => {
      this._isShowAskAI = isShowAskAI;
    });
  };

  get conversationId() {
    return this._conversationId;
  }
  getConversationId = (productId?: number) => {
    if (productId === 4) {
      return this._conversationId?.HM ?? "";
    }
    if (productId === 10) {
      return this._conversationId?.CX ?? "";
    }
    return this._conversationId?.Common ?? "";
  };
  setConversationId = (id: string) => {
    runInAction(() => {
      this._conversationId.Common = id;
    });
  };
}

export const askAIServiceStore = new AskAIService();
const askAIServiceContext = createContext(askAIServiceStore);
export const useAskAIServiceStore = () => {
  return useContext(askAIServiceContext);
};
