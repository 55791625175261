import Actions from "@app/core/actions/_index";
import { ChatSidebar } from "@app/core/chat/list/_index";
import { Comments } from "@app/core/comments/_index";
import { Documents } from "@app/core/documents/_index";
import Inspections from "@app/core/inspections/inspections-accordion/_index";
import Issues from "@app/core/issues/_index";
import NCItems from "@app/core/nc-items/_index";
import Related from "@app/core/related/_index";
import { SamplesAccordion } from "@app/core/samples/_index";
import Temperature from "@app/core/temperature/_index";
import { getPremiseRecords } from "@app/products/hm/api";
import { InspectionForm } from "@app/products/hm/inspections/[id]/components/child-screens/general/_index";
import FSPObserved from "@app/products/hm/inspections/[id]/components/forms/exist/components/fsp-observed/_index";
import { InspectionContactsTab } from "@app/products/hm/inspections/[id]/components/reference-sidebar/contacts/_index";
import { InspectionDetailsTab } from "@app/products/hm/inspections/[id]/components/reference-sidebar/details/_index";
import { InspectionHistoryTab } from "@app/products/hm/inspections/[id]/components/reference-sidebar/history/_index";
import { useInspectionsStore } from "@app/products/hm/inspections/[id]/store";
import { IInspectionRecordsState } from "@app/products/hm/model";
import { usePremisesStore } from "@app/products/hm/premises/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ExistManageInspection = observer(() => {
  const { id } = useParams<{ id: string }>();
  const {
    isLoading,
    inspections,
    responseLoadError,
    referenceData,
    premisesId,
    loadInspections,
  } = useInspectionsStore();

  const { premisesId: parentId } = usePremisesStore();
  const [inspectionsRecord, setInspectionsRecord] =
    useState<IInspectionRecordsState>();
  const { currentUserInfo } = useGlobalStore();
  const managePageUrl = window.location.pathname;
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: referenceData.inspection.InspectionLabel,
      LinkUrl: managePageUrl,
      LinkText: `Heath Manager - Inspections - ${premisesId ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.HealthManager,
      RecordType_ENUM: RECORDTYPE.HealthManager_Premises, // TODO: Change RECORDTYPE
      Record_ID: premisesId ? +premisesId : 0,
    },
  ];

  const handleOnReload = () => {
    loadInspections(id, parentId);
  };

  useEffectOnce(() => {
    getPremiseRecords(id, RECORDTYPE.HealthManager_Premises).then((res) => {
      setInspectionsRecord(res.data);
    });
  });

  const [showSlideBar, setShowSlideBar] = useState(true);

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Actions",
      totalRecord: inspectionsRecord?.actions,
      component: (
        <Actions id={premisesId} recordType={RECORDTYPE.CORE_Inspection} />
      ),
    },
    {
      title: "Comments",
      totalRecord: inspectionsRecord?.comments,
      component: (
        <Comments id={premisesId} recordType={RECORDTYPE.CORE_Inspection} />
      ),
    },
    {
      title: "Contacts",
      totalRecord: inspectionsRecord?.contacts,
      component: (
        <Contacts id={premisesId} recordType={RECORDTYPE.CORE_Inspection} />
      ),
    },
    {
      title: "Documents",
      totalRecord: inspectionsRecord?.documents,
      component: (
        <Documents
          id={parseInt(premisesId + "")}
          recordType={RECORDTYPE.CORE_Inspection}
        />
      ),
    },
    {
      title: "Inspections",
      totalRecord: inspectionsRecord?.inspections,
      component: (
        <Inspections id={premisesId} recordType={RECORDTYPE.CORE_Inspection} />
      ),
    },
    {
      title: "Samples",
      totalRecord: inspectionsRecord?.samples,
      component: (
        <SamplesAccordion
          id={premisesId}
          recordType={RECORDTYPE.CORE_Inspection}
        />
      ),
    },
    {
      title: "NC Items",
      totalRecord: inspectionsRecord?.samples,
      component: (
        <NCItems id={premisesId} recordType={RECORDTYPE.CORE_Inspection} />
      ),
    },
    {
      title: "Temperature",
      totalRecord: inspectionsRecord?.temperature,
      component: (
        <Temperature id={premisesId} recordType={RECORDTYPE.CORE_Inspection} />
      ),
    },
    {
      title: "FSP Observed",
      totalRecord: inspectionsRecord?.fspobserved,
      component: (
        <FSPObserved id={premisesId} recordType={RECORDTYPE.CORE_Inspection} />
      ),
    },
    {
      title: "Related",
      totalRecord: inspectionsRecord?.related,
      component: (
        <Related id={premisesId} recordType={RECORDTYPE.CORE_Inspection} />
      ),
    },
    {
      title: "Issues",
      totalRecord: inspectionsRecord?.samples,
      component: (
        <Issues id={premisesId} recordType={RECORDTYPE.CORE_Inspection} />
      ),
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <InspectionDetailsTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <InspectionContactsTab /> },
      {
        title: "History",
        component: <InspectionHistoryTab />,
      },
      {
        title: "Ask AI",
        component: <ChatSidebar productId={4} />,
      },
    ],
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Inspection" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={handleOnReload}
        />
      ) : (
        <>
          <FormTitle title={referenceData.inspection.InspectionLabel} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              ></CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Fees"} />
                <CCNavButton title={"Interview"} />
                <CCNavButton title={"Notice"} />
                <CCNavButton title={"Samples"} />
              </CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,
              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"Add NC Item"} />
                <CCNavButton title={"AFSA"} />
                <CCNavButton title={"Add Temp Recorded"} />
                <CCNavButton title={"Add FSP Observed"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {inspections && (
                  <CCGeneralPanel component={<InspectionForm />} />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
