import { ChatSidebar } from "@app/core/chat/list/_index";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { StandardDocumentButton } from "@app/products/crms/service-standards/[id]/components/forms/components/action-bar/buttons/standard-document/_index";
import { CRMSServiceStandardForm } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/_index";
import { CRMSServiceStandardsDetailsTab } from "@app/products/crms/service-standards/[id]/components/forms/components/reference-sidebar/details/_index";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { generateServiceStandardFormTitle } from "@app/products/crms/service-standards/[id]/util";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ExistedCRMSServiceStandard = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_ServiceStandard,
    productType: PRODUCT_TYPE_NUMBER.CustomerService,
  });

  const managePageUrl = window.location.pathname;
  const [showSlideBar, setShowSlideBar] = useState(true);
  const {
    loadCRMSServiceStandard,
    crmsServiceStandard,
    crmsServiceStandardId,
    responseLoadError,
    isLoading,
    serviceStandardLovs,
    onSubmit,
    accessRight,
  } = useCRMSServiceStandardStore();

  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: "Service Standard",
      LinkUrl: managePageUrl,
      LinkText: `${CRMS_PRODUCT_NAME} - Service Standards - ${
        crmsServiceStandardId ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CustomerService,
      RecordType_ENUM: RECORDTYPE.CUSTOMERSERVICE_ServiceStandard,
      Record_ID: crmsServiceStandardId ? +crmsServiceStandardId : 0,
    },
  ];
  const isDisabled = accessRight === ACCESSRIGHTS.CanRead;

  useEffectOnce(() => {
    loadCRMSServiceStandard(parseInt(id));
  });

  return (
    <>
      <Loading isLoading={isLoading || isLoadingPermission} isFullScreen />
      <FormNavigation title={"Service Standard"} />

      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCRMSServiceStandard(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle
            title={
              crmsServiceStandard &&
              generateServiceStandardFormTitle(crmsServiceStandard)
            }
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
                disabled={
                  isDisabled || !checkPermissions(FormAction.CORE_ALLOW_EDIT)
                }
              />,
              <CCNavButton title={"Add"} type="sub">
                <StandardDocumentButton
                  dataSetFilter={MAILMERGEDATASET?.CUSTOMERSERVICE_Event}
                  recordType={RECORDTYPE.CUSTOMERSERVICE_StandardDocument}
                  productType={PRODUCT_TYPE_NUMBER.CustomerService}
                  serviceStandardID={crmsServiceStandardId}
                  isDisabled={
                    !checkPermissions(
                      FormAction.CRMS_SERVICESTANDARD_ADD_STANDARDDOCUMENT
                    )
                  }
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {crmsServiceStandard && serviceStandardLovs && (
                  <CRMSServiceStandardForm />
                )}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <CRMSServiceStandardsDetailsTab />,
                    },
                    {
                      title: "Ask AI",
                      component: <ChatSidebar productId={10} />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
