import { odataCoreGetInspectionTypesByProductType } from "@app/core/components/common/utils";
import { getInspectionLOVs, getSiteUser } from "@app/core/inspections/[id]/api";
import {
  optionOfficer,
  renderOptionInspectionsType,
} from "@app/core/inspections/[id]/components/child-screens/general/components/inspection-form-element/config";
import {
  BubbleUpType,
  IParentInspectionsSection,
  Inspection,
  InspectionUpdateTriggers,
  Officers,
} from "@app/core/inspections/[id]/model";
import { useInspectionsStore } from "@app/core/inspections/[id]/store";
import {
  productTypeNumberLogic,
  validatorTimeValueWithMax,
  validatorTimeValueWithMin,
} from "@app/core/inspections/[id]/util";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import {
  ContactRelationshipType,
  InspectionClassification,
} from "@common/constants/enumerations";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { Contact } from "@common/models/contact";
import { Svc_InspectionType } from "@common/models/inspectionType";
import { mapEnum, nameOfFactory } from "@common/utils/common";
import {
  requiredValidator,
  validateRequired,
} from "@common/utils/field-validators";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTimePicker } from "@components/cc-time-picker/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { cloneDeep, isNil, isNull } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { InspectionParentSection } from "./components/inspection-parent-section/_index";

export interface IInspectionFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<Inspection>();
const AffectScheduleData = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const InspectionFormElement = observer(
  ({ formRenderProps }: IInspectionFormElementProps) => {
    const isNew = useIsNew();
    const { valueGetter, onChange } = formRenderProps;
    const {
      inspections,
      inspectionsLOVs,
      parent,
      setInspectionsLOVs,
      setIsLoading,
      setResponseLoadError,
      isInactive,
      isDisabledAffectSchedule,
      isRelatedNoticeRequired,
      isRelatedInspectionRequired,
      handleDisplayInspectionType,
      setFormIsModified,
    } = useInspectionsStore();

    const { state } = useLocation<{
      parent?: IParentInspectionsSection;
    }>();

    const productType: PRODUCT_TYPE_NUMBER = productTypeNumberLogic(
      parent?.recordType
    );

    const inspectionsFormObj: Inspection = valueGetter("");
    const isShowRelatedNotice =
      inspectionsFormObj?.InspectionType?.Flag_RelatedNotice ?? true;
    const isShowRelatedIncident = inspectionsFormObj?.InspectionType
      ? !isNull(inspectionsFormObj?.InspectionType?.Flag_RelatedIncident)
        ? inspectionsFormObj?.InspectionType?.Flag_RelatedIncident
        : false
      : true;

    const isDisableRelatedInspection =
      inspectionsFormObj?.RelatedInspection_ID &&
      inspectionsFormObj?.Inspection_ID;

    const inspectionsTypeChangeHandler = async (
      inspectionsType: Svc_InspectionType
    ) => {
      if (inspectionsType) {
        let inspectionType = undefined;
        let inspectionsLOVs = undefined;
        setIsLoading(true);

        const bubbleUp = isNew
          ? state?.parent?.bubbleUps
          : inspections?._BubbleUps;
        if (!bubbleUp || bubbleUp?.length === 0) {
          setIsLoading(false);
          appNotificationStore.pushNotification({
            type: "error",
            autoClose: false,
            title:
              "This inspection doesn't have parent info. Please go back to parent page and create again!",
          });
          return;
        }
        const responseLOVs = await getInspectionLOVs(
          bubbleUp,
          inspectionsType?.ID ?? 0
        );
        setIsLoading(false);
        if (isSuccessResponse(responseLOVs)) {
          setInspectionsLOVs(responseLOVs.data);
          inspectionType = cloneDeep(responseLOVs.data?.InspectionTypeDetail);
          inspectionsLOVs = cloneDeep(responseLOVs.data);
          setFormIsModified(true);
        } else {
          setResponseLoadError({
            status: responseLOVs.status,
            error: responseLOVs.error,
          });
        }

        const ancestorBubbleUps = inspections?._BubbleUps?.find(
          (bubbleUp: BubbleUpIdentifier) =>
            mapEnum(bubbleUp?.BubbleUpType_ENUM, BubbleUpType) ===
            BubbleUpType.Ancestor
        );
        onChange(nameOf("Compliance_KWD"), { value: null });
        if (inspectionType) {
          if (
            mapEnum(
              ancestorBubbleUps?.SourceIdentifier._RecordSourceType_ENUM,
              RECORDTYPE
            ) !== RECORDTYPE.Core_InspectionPlanner
          ) {
            if (
              inspectionType.InspectionClassification_ENUM ===
              InspectionClassification.ANIMALS_NONCOMPLIANCE
            ) {
              appNotificationStore.pushNotification({
                type: "warning",
                autoClose: false,
                title:
                  "Invalid Inspection Type please select a different Inspection type.The Animal Non Compliance Inspection type is only used in the Planned Area Inspections",
              });
              return;
            }
            onChange(nameOf("InspectionType"), {
              value: inspectionType,
            });
            onChange(nameOf("InspectionType_ID"), {
              value: inspectionType?.InspectionType_ID,
            });
          }
        }
        handleDisplayInspectionType(
          true,
          inspectionType,
          inspectionsLOVs,
          inspectionsFormObj
        );
        if (inspectionsFormObj?.InspectionType) {
          if (
            inspectionsFormObj?.InspectionType
              ?.InspectionClassification_ENUM ===
            InspectionClassification.FOODPREMISES_GLENEIRA_FOLLOWUP_RISKASSESSMENT
          )
            onChange(nameOf("FollowUpRequired"), { value: false });

          if (
            inspectionsFormObj.InspectionType.InspectionClassification_ENUM ===
              InspectionClassification.INFECTION_CONTROL &&
            inspectionsFormObj.InspectionType.Flag_IsScoring != null &&
            inspectionsFormObj.InspectionType.Flag_IsScoring === true
          ) {
            appNotificationStore.pushNotification({
              type: "warning",
              autoClose: false,
              title: "Please Note :-",
              description:
                "Please enter Infection Control scoring details before exiting to Premises",
            });
          }
        }
      } else {
        setInspectionsLOVs(undefined);
        onChange(nameOf("InspectionType"), {
          value: undefined,
        });
        onChange(nameOf("InspectionType_ID"), {
          value: 0,
        });
      }
    };

    const officerChangeHandler = async (officer: Officers) => {
      if (officer) {
        setIsLoading(true);
        const response = await getSiteUser(officer?.ID);
        setIsLoading(false);
        if (isSuccessResponse(response) && response?.data) {
          onChange(nameOf("ActionOfficer"), {
            value: response?.data,
          });
          onChange(nameOf("ActionOfficer_ID"), {
            value: response.data.Contact_ID,
          });
        } else {
          appNotificationStore.pushNotification({
            title: "Officer is not found",
            type: "error",
            autoClose: false,
          });
        }
      } else {
        onChange(nameOf("ActionOfficer"), {
          value: undefined,
        });
        onChange(nameOf("ActionOfficer_ID"), {
          value: undefined,
        });
      }
    };

    const secondOfficerChangeHandler = async (officer: Officers) => {
      if (officer) {
        setIsLoading(true);
        const response = await getSiteUser(officer?.ID);
        setIsLoading(false);
        if (isSuccessResponse(response) && response?.data) {
          onChange(nameOf("SecondaryOfficer"), {
            value: response?.data,
          });
          onChange(nameOf("SecondaryOfficer_ID"), {
            value: response.data.Contact_ID,
          });
        } else {
          appNotificationStore.pushNotification({
            title: "Officer is not found",
            type: "error",
            autoClose: false,
          });
        }
      } else {
        onChange(nameOf("SecondaryOfficer"), {
          value: undefined,
        });
        onChange(nameOf("SecondaryOfficer_ID"), {
          value: undefined,
        });
      }
    };

    const updateSaveTriggers = (triggers: InspectionUpdateTriggers) => {
      const saveTriggerValue = valueGetter("_SaveTriggers") ?? [];

      let saveTriggers: InspectionUpdateTriggers[] = Array.isArray(
        saveTriggerValue
      )
        ? saveTriggerValue
        : [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(nameOf("_SaveTriggers"), {
          value: saveTriggers,
        });
      }
    };

    const personContactedChangeHandler = (event: ComboBoxChangeEvent) => {
      const value = event.value;
      if (isNil(value)) {
        onChange(nameOf("PersonContacted"), {
          value: null,
        });
        onChange(nameOf("PersonContacted_RID"), {
          value: null,
        });
        updateSaveTriggers(InspectionUpdateTriggers.UpdatePersonContacted);
      } else {
        let relationshipID = undefined;
        let sysTimeStamp = null;
        if (
          inspectionsFormObj?.PersonContacted_RID &&
          inspectionsFormObj?.PersonContacted?.Contact?.Contact_ID ===
            event?.value?.Contact_ID &&
          inspectionsFormObj?.PersonContacted?.Sys_TimeStamp
        ) {
          relationshipID = inspectionsFormObj?.PersonContacted_RID;
          onChange(nameOf("PersonContacted_RID"), {
            value: valueGetter(nameOf("PersonContacted_RID")),
          });
          sysTimeStamp = valueGetter(nameOf("PersonContacted"))?.Sys_TimeStamp;
        } else {
          onChange(nameOf("PersonContacted_RID"), {
            value: 0,
          });
        }

        onChange(nameOf("AuditorNumber"), {
          value: value?.Extension?.LicenceNo,
        });
        onChange(nameOf("PersonContacted"), {
          value: {
            ContactRelationshipType_ENUM:
              ContactRelationshipType.HM_Inspection_PersonContacted,
            Contact: value,
            RID: relationshipID ?? 0,
            Sys_TimeStamp: sysTimeStamp,
          },
        });
        updateSaveTriggers(InspectionUpdateTriggers.UpdatePersonContacted);
      }
    };

    const generatePhoneContact = (contact: Contact) => {
      if (!contact) return "";
      return (
        <>
          {contact._HomePhone && (
            <a href={`tel:${contact._HomePhone}`} className="cc-contact">
              {contact._HomePhone}(H)
            </a>
          )}
          {contact._WorkPhone && (
            <a href={`tel:${contact._WorkPhone}`} className="cc-contact">
              {contact._WorkPhone}(W)
            </a>
          )}
          {contact._Mobile && (
            <a href={`tel:${contact._Mobile}`} className="cc-contact">
              {contact._Mobile}(M)
            </a>
          )}
          {contact.Email && (
            <a href={`mailto:${contact.Email}`} className="cc-contact">
              {contact.Email}
            </a>
          )}
        </>
      );
    };

    const validateTimeValueWithMax = useCallback(
      (value: Date | null) =>
        validatorTimeValueWithMax(
          value,
          valueGetter(nameOf("TimeCompleted")),
          "Time started",
          "Time completed"
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(nameOf("TimeCompleted"))]
    );

    const validateTimeValueWithMin = useCallback(
      (value: Date | null) =>
        validatorTimeValueWithMin(
          value,
          valueGetter(nameOf("TimeStarted")),
          "Time started",
          "Time completed"
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(nameOf("TimeStarted"))]
    );

    return (
      <FormElement>
        <InspectionParentSection recordType={parent?.recordType} />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Inspection type" isMandatory />
              <Field
                component={InputPickerSearch}
                name={nameOf("InspectionType")}
                nameDisplay="Name"
                value={valueGetter(nameOf("InspectionType"))}
                options={renderOptionInspectionsType(
                  odataCoreGetInspectionTypesByProductType(productType)
                )}
                onChange={inspectionsTypeChangeHandler}
                validator={requiredValidator}
                disabled={isInactive}
              />
            </div>

            <div className="cc-field">
              <CCLabel title="Officer" isMandatory />
              <Field
                component={InputPickerSearch}
                name={nameOf("ActionOfficer")}
                nameDisplay="DisplayName"
                value={valueGetter(nameOf("ActionOfficer"))}
                options={optionOfficer}
                onChange={officerChangeHandler}
                validator={requiredValidator}
                disabled={isInactive}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Date inspected" isMandatory />
              <Field
                name={nameOf("DateInspected")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                validator={requiredValidator}
                disabled={isInactive}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Time started" />
              <Field
                name={nameOf("TimeStarted")}
                component={CCTimePicker}
                disabled={isInactive}
                validator={validateTimeValueWithMax}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Time completed" />
              <Field
                name={nameOf("TimeCompleted")}
                component={CCTimePicker}
                disabled={isInactive}
                validator={validateTimeValueWithMin}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Reason" isMandatory />
              <Field
                validator={validateRequired}
                name={nameOf("Reason_KWD")}
                textField="Value"
                dataItemKey="Key"
                data={inspectionsLOVs?.Reason}
                component={CCSearchComboBox}
                isUseDefaultOnchange
                disabled={isInactive}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Reference number" />
              <Field
                name={nameOf("ReferenceNo")}
                component={CCInput}
                disabled={isInactive}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Compliance" isMandatory />
              <Field
                validator={validateRequired}
                name={nameOf("Compliance_KWD")}
                textField="Value"
                dataItemKey="Key"
                data={inspectionsLOVs?.Compliance}
                component={CCSearchComboBox}
                isUseDefaultOnchange
                disabled={isInactive}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Affect planned schedule" isMandatory />
              <Field
                name={nameOf("AffectSchedule")}
                component={CCRadioGroup}
                layout={"horizontal"}
                data={AffectScheduleData}
                disabled={isDisabledAffectSchedule || isInactive}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Secondary officer" />
              <Field
                name={nameOf("SecondaryOfficer")}
                component={InputPickerSearch}
                nameDisplay="DisplayName"
                value={valueGetter(nameOf("SecondaryOfficer"))}
                options={optionOfficer}
                onChange={secondOfficerChangeHandler}
                disabled={isInactive}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />

        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Person contact" />
              <Field
                name={`${nameOf("PersonContacted")}.Contact`}
                component={ContactPicker}
                placeholder={"Person contact"}
                onChange={personContactedChangeHandler}
                value={valueGetter(nameOf("PersonContacted"))?.Contact}
                onError={(error: any) => {
                  appNotificationStore.pushNotification({
                    type: "error",
                    title: "Pick contact errors",
                    description: error,
                    autoClose: false,
                  });
                }}
                disabled={isInactive}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Phone"
                value={generatePhoneContact(
                  valueGetter(nameOf("PersonContacted"))?.Contact
                )}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Position" />
              <Field
                name={nameOf("PersonPosition_KWD")}
                textField="Value"
                dataItemKey="Key"
                data={inspectionsLOVs?.Positions}
                component={CCSearchComboBox}
                isUseDefaultOnchange
                disabled={isInactive}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />

        <div className="cc-field-group">
          <div className="cc-field">
            <CCLabel
              title="Related inspection"
              isMandatory={isRelatedInspectionRequired}
            />
            <Field
              name={nameOf("RelatedInspection_ID")}
              dataItemKey="Key"
              textField="Value"
              data={inspectionsLOVs?.RelatedInspection}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              validator={
                isRelatedInspectionRequired ? requiredValidator : undefined
              }
              disabled={isDisableRelatedInspection || isInactive}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Related action" />
            <Field
              name={nameOf("RelatedAction_ID")}
              dataItemKey="Key"
              textField="Value"
              data={inspectionsLOVs?.RelatedActions}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isInactive}
            />
          </div>
          {isShowRelatedNotice && (
            <div className="cc-field">
              <CCLabel
                title="Related notice"
                isMandatory={isRelatedNoticeRequired}
              />
              <Field
                name={nameOf("RelatedNotice_ID")}
                dataItemKey="Key"
                textField="Value"
                data={inspectionsLOVs?.RelatedNotice}
                isUseDefaultOnchange
                component={CCSearchComboBox}
                validator={
                  isRelatedNoticeRequired ? requiredValidator : undefined
                }
                disabled={isInactive}
              />
            </div>
          )}
          {isShowRelatedIncident && (
            <div className="cc-field">
              <CCLabel title="Related incident" />
              <Field
                name={nameOf("RelatedIncident_ID")}
                dataItemKey="Key"
                textField="Value"
                data={inspectionsLOVs?.RelatedIncident}
                isUseDefaultOnchange
                component={CCSearchComboBox}
                disabled={isInactive}
              />
            </div>
          )}
        </div>
        <hr className="cc-divider" />

        <div className="cc-field-group">
          <div className="cc-field">
            <CCLabel title="Description" />
            <Field
              rows={5}
              name={nameOf("Description")}
              component={CCTextArea}
              disabled={isInactive}
            />
          </div>
        </div>
      </FormElement>
    );
  }
);
