import { ChatSidebar } from "@app/core/chat/list/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { CRMSEventContactTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/contact/_index";
import { CRMSEventDetailsTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/details/_index";
import { CRMSGuidanceTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/guidance/_index";
import { CRMSHistoryTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/history/_index";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { BatchUpdateButton } from "@app/products/crms/components/action-bar/buttons/batch-update/_index";
import { CloseEventsButton } from "@app/products/crms/components/action-bar/buttons/close-event/_index";
import { NewEventWithSameDetailsButton } from "@app/products/crms/components/action-bar/buttons/new-event-with-same-details/_index";
import { NewEventWithSameIssueButton } from "@app/products/crms/components/action-bar/buttons/new-event-with-same-issue/_index";
import { NewEventButton } from "@app/products/crms/components/action-bar/buttons/new-event/_index";
import { CrmsActionBarNavDropdown } from "@app/products/crms/components/action-bar/nav-dropdown/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { crmsRoute } from "@app/products/crms/route";
import {
  colByAsset,
  generateUnresolvedEventsByAssetBySetting,
} from "@app/products/crms/unresolved-events/by-asset/config";
import { CRMSByAssetBookmark } from "@app/products/crms/unresolved-events/by-asset/util";
import { unresolvedEventsRoute } from "@app/products/crms/unresolved-events/route";
import {
  CRMSBookmark,
  getEventsByAssetOdataURL,
} from "@app/products/crms/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<EventView>();
const gridId = getUUID();

export default observer(() => {
  const { settings } = useCommonCoreStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_Event,
    productType: PRODUCT_TYPE_NUMBER.CustomerService,
  });

  useCCListViewActionBar({
    title: crmsRoute.name,
    leftComponents: [
      <CrmsActionBarNavDropdown category={unresolvedEventsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title="New"
        type="sub"
        disabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      >
        <NewEventButton />
        <NewEventWithSameDetailsButton />
        <NewEventWithSameIssueButton />
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        dataSetFilter={MAILMERGEDATASET.CUSTOMERSERVICE_Event}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        isDisabled={
          !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMUNICATION)
        }
      />,
      <DeleteButton
        recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
        refreshGridIds={[gridId]}
        gridId={gridId}
      />,
      <CCNavButton title={"Tools"} type="more">
        <BatchUpdateButton
          isDisabled={!checkPermissions(FormAction.CRMS_TOOLS_BATCHUPDATE)}
          gridId={gridId}
        />
        <CloseEventsButton
          isDisabled={!checkPermissions(FormAction.CRMS_TOOLS_CLOSEEVENT)}
          gridId={gridId}
        />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CRMS_ROUTE}
        productType={PRODUCT_TYPE.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        detail={CRMSBookmark.getBookmarkDetail}
        displayName={CRMSBookmark.getBookmarkDisplayName}
        listViewDisplayName={CRMSByAssetBookmark.getBookmarkListViewDisplayName}
        listViewDetail={CRMSByAssetBookmark.getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CRMSEventDetailsTab /> },
      {
        title: "Map",
        component: (
          <MapTab
            addressField={nameOf("Location")}
            zoom={18}
            recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
            isDisabled={!checkPermissions(FormAction.CRMS_TOOLS_VIEWONMAP)}
            isInManagePage={false}
          />
        ),
      },
      {
        title: "Contacts",
        component: <CRMSEventContactTab />,
      },
      {
        title: "Guidance",
        component: <CRMSGuidanceTab />,
      },
      {
        title: "History",
        component: <CRMSHistoryTab />,
      },
      {
        title: "Ask AI",
        component: <ChatSidebar productId={10} />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        gridId={gridId}
        columnFields={generateUnresolvedEventsByAssetBySetting({
          settings,
          defaultConfig: colByAsset,
        })}
        primaryField={nameOf("ID")}
        dataUrl={getEventsByAssetOdataURL(true, true)}
        state={{
          sort: [{ field: nameOf("ID"), dir: "desc" }],
          group: [{ field: nameOf("AssetSiteName"), dir: "asc" }],
        }}
      />
    </LoadingPermissionWrapper>
  );
});
