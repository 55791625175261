import { ChatSidebar } from "@app/core/chat/list/_index";
import { NewAssetButton } from "@app/products/hm/components/action-bar/buttons/new-asset/_index";
import { NewEventButton } from "@app/products/hm/components/action-bar/buttons/new-event/_index";
import { NewHardRubbishButton } from "@app/products/hm/components/action-bar/buttons/new-hard-rubbish/_index";
import { NewIncidentButton } from "@app/products/hm/components/action-bar/buttons/new-incident/_index";
import { NewMessageButton } from "@app/products/hm/components/action-bar/buttons/new-message/_index";
import { NewPayrollEventButton } from "@app/products/hm/components/action-bar/buttons/new-payroll-event/_index";
import { NewPremiseButton } from "@app/products/hm/components/action-bar/buttons/new-premises/_index";
import { NewWordOrderButton } from "@app/products/hm/components/action-bar/buttons/new-word-order/_index";
import { NewReportsButton } from "@app/products/hm/components/action-bar/buttons/reports/_index";
import { HMActionBarNavDropdown } from "@app/products/hm/components/action-bar/nav-dropdown/_index";
import { HMUrl } from "@app/products/hm/config";
import { fsrRoute } from "@app/products/hm/fsr/route";
import { InspectionContactsTab } from "@app/products/hm/inspections/[id]/components/reference-sidebar/contacts/_index";
import { InspectionDetailsTab } from "@app/products/hm/inspections/[id]/components/reference-sidebar/details/_index";
import { InspectionHistoryTab } from "@app/products/hm/inspections/[id]/components/reference-sidebar/history/_index";
import { hmRoute } from "@app/products/hm/route";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { colHMFSRKeywords } from "./config";

export default () => {
  useCCListViewActionBar({
    title: hmRoute.name,
    leftComponents: [<HMActionBarNavDropdown category={fsrRoute.path} />],
    centerComponents: [
      <NewEventButton />,
      <NewPremiseButton />,
      <NewIncidentButton />,
      <NewHardRubbishButton />,
      <NewAssetButton />,
      <NewWordOrderButton />,
      <NewReportsButton />,
      <CCNavButton title="More Options" type="more">
        <NewPayrollEventButton />

        <NewMessageButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <InspectionDetailsTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <InspectionContactsTab /> },
      {
        title: "History",
        component: <InspectionHistoryTab />,
      },
      {
        title: "Ask AI",
        component: <ChatSidebar productId={4} />,
      },
    ],
  });
  return (
    <CCProductListView
      columnFields={colHMFSRKeywords}
      dataUrl={HMUrl.GET_VIEW_HM_FSR_KEYWORDS}
      primaryField="ID"
    />
  );
};
